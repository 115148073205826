import '../scss/plugins/_swiper.scss'

import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules'

const controlsSettings = {
	pagination: {
		el: '[data-swiper-pagination]',
		type: 'fraction',
	},

	navigation: {
		nextEl: '[data-swiper-button-next]',
		prevEl: '[data-swiper-button-prev]',
	},
}

const cardsBreakpoints = {
	spaceBetween: 24,
	slidesPerView: 1,
	breakpoints: {
		744: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 4,
		},
	},
}

const sliderGalleries = document.querySelectorAll('[data-swiper-gallery]')
if (sliderGalleries.length) {
	sliderGalleries.forEach(gallery => {
		const slidesPerView = Number(gallery.getAttribute('data-slides-per-view'))
		let slidesPerViewTablet = 1
		if (slidesPerView >= 3) {
			slidesPerViewTablet = Math.round(slidesPerView / 2)
		}
		const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1
		const sliderGallery = new Swiper(gallery, {
			...controlsSettings,
			modules: [Navigation, Pagination],
			slidesPerView: slidesPerViewMobile,
			spaceBetween: 14,
			autoHeight: true,
			breakpoints: {
				667: {
					slidesPerView: slidesPerViewTablet,
				},
				992: {
					slidesPerView: slidesPerView,
				},
			},
		})
	})
}

const testimonials = new Swiper('[data-swiper-testimonials]', {
	...controlsSettings,
	modules: [Navigation, Pagination, EffectFade, Autoplay],
	autoplay: {
		delay: 3500,
		pauseOnMouseEnter: true,
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	grabCursor: true,
	spaceBetween: 24,
	slidesPerView: 1,
})

const courses = new Swiper('[data-swiper-courses]', {
	...controlsSettings,
	...cardsBreakpoints,
	modules: [Navigation, Pagination],
	centerInsufficientSlides: true,
})

const productsSlides = document.querySelectorAll(`[data-swiper-products] li.product`)
productsSlides.forEach(slide => slide.classList.add('swiper-slide'))

const products = new Swiper(`[data-swiper-products]`, {
	modules: [Navigation, Pagination],
	centerInsufficientSlides: true,
	...controlsSettings,
	...cardsBreakpoints,
})
